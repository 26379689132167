import { useEffect, useState } from "react";
import "./TicTac.css";

function getWinner(box: string[]) {
  console.log('box:', box);
  const winPattern = [
    [0,1,2],
    [3,4,5],
    [6,7,8],
    [0,3,6],
    [1,4,7],
    [2,5,8],
    [0,4,8],
    [2,4,6]
  ];
  const xPlays: number[] = [];
  const oPlays: number[] = [];

  box.forEach((tile, index) => {
    if(tile === 'X') xPlays.push(index);
    if(tile === 'O') oPlays.push(index);
  });

  const xWins = winPattern.filter((pattern) => {
    const match = pattern.filter(p => xPlays.includes(p) );
    return match.join('') === pattern.join('');
  });
  const oWins = winPattern.filter((pattern) => {
    const match = pattern.filter(p => oPlays.includes(p) );
    return match.join('') === pattern.join('');
  });

  let winner;
  if(xWins.length){
    winner = 'X';
  }
  if(oWins.length){
    winner = 'O';
  }

  return winner;
}

function Box({
  setPlayer,
  player,
  setBox,
  index,
  value,
  winner
}: any ) {
  return (
    <div
      className='tile'
      onClick={() => {
        if(winner){
          return alert(`${winner} has won the game`);
        }
        setBox((prev: string[]) => {
          prev[index] = player;
          return prev;
        });
        setPlayer((prev: string) => prev === 'X' ? 'O':'X' );
      }}
    >
      {value}
    </div>
  )
}

export function Counter() {
  const [player, setPlayer] = useState<string>('X');
  /**
   * [
   *  []
   *  []
   *  []
   * ]
   */
  const boxData: string[] = new Array(9);
  boxData.fill('');
  const [box, setBox] = useState<string[]>(boxData);
  const [gameWinner, setGameWinner] = useState<string | undefined>(undefined);

  useEffect(() => {
    const winner = getWinner(box);
    if(winner) {
      setGameWinner(winner);
    }

  }, [box, player]);

  return (
    <div className='box-container'>
      <h2>{`Player: ${player}`}</h2>
      <div className="reset-container">
        <button
          onClick={() => {
            setBox(boxData);
            setGameWinner(undefined);
          }}
        >
          Reset
        </button>
      </div>
      <div className='box'>
        {
          box.map((column, index) => {
            return (
              <Box
                key={index}
                value={column}
                index={index}
                setPlayer={setPlayer}
                player={player}
                box={box}
                setBox={setBox}
                winner={gameWinner}
              />
            );
          })
        }
      </div>
    </div>
  );
}
