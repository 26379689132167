import { Layout } from "antd";

const { Footer } = Layout;

function MainFooter() {
    return (
        <Footer style={{ textAlign: 'center' }}>
            Rentfree LTD ©2021
        </Footer>
    )
}

export default MainFooter;