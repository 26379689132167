import { Menu } from "antd";
import { Header } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";

interface link {
    name: string,
    href: string
};

const linkLeft: link[] = [
    {
        name: "Home",
        href: "/"
    },
    {
        name: "Counter",
        href: "/counter"
    },
];
const linkRight: link[] = [
    {
        name: "Register",
        href: "/login"
    },
    {
        name: "Login",
        href: "/login"
    },
];

function MainHeader(){
    return (
        <Header className="header">
            <div className="logo" />
            <div className="mainMenu">
                <div className="menuLeft" style={{float: 'left'}}>
                    {/* Left Menu */}
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['Home']}
                    >
                        {
                            linkLeft.map(
                                link => <Menu.Item key={link.name} style={{marginLeft: '10px'}}>
                                            {<Link to={link.href}>{link.name}</Link>}
                                        </Menu.Item>
                            )
                        }
                    </Menu>
                </div>
                <div className="menuright" style={{float: 'right'}}>
                    {/* Right Menu */}
                    <Menu
                        theme="dark"
                        mode="horizontal"
                    >
                        {
                            linkRight.map(
                                link => <Menu.Item key={link.name} style={{marginLeft: '10px'}}>
                                            {<Link to={link.href}>{link.name}</Link>}
                                        </Menu.Item>
                            )
                        }
                    </Menu>
                </div>
            </div>
        </Header>
    );
}

export default MainHeader;