import { Breadcrumb } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import { Outlet } from "react-router-dom";
import MainFooter from "./MainFooter";
import Header from "./MainHeader";

function Main() {
    return (
        <Layout className="layout">
            <Header />
            <Content className="site-layout" style={{ padding: '0 50px' }}>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>List</Breadcrumb.Item>
                    <Breadcrumb.Item>App</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-content">
                    <Outlet />
                </div>
            </Content>
            <MainFooter />
        </Layout>
    )
}

export default Main;