import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { LoginError, LoginState } from "../types/login.types";

const initialState: LoginState = {
    username: '',
    token: '',
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        login: (state) => {
            console.log('login started');
        },
        loginFailed: (state, action: PayloadAction<LoginError | string>) => {
            // Do something...
        },
        loginSuccess: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
            console.log("login success");
        },
        logout: (state) => {
            state.token = '';
        }
    }
});

export const { login, loginFailed, loginSuccess, logout} = loginSlice.actions;

export const selectLoginState = (state: RootState) => state.login;

export default loginSlice.reducer;