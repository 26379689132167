import { Col, Row, Form, Input, Checkbox, Button, message } from "antd";
import { useAppDispatch, useAppSelector } from "../app/hooks";

import styles from "./Login.module.css";
import loginService from "./services/loginService";
import { login, loginFailed, loginSuccess, selectLoginState } from "./store/loginSlice";

export default function Login() {
    const loginState = useAppSelector(selectLoginState);
    const dispatch = useAppDispatch();

    if(loginState.token) {
        return (
            <h2>You are already logged in</h2>
        );
    }

    const onFinish = (values: any) => {
        loginService(values).then((response) => {
            if(response && response.token) {
                dispatch(loginSuccess(response.token));
            }else {
                //message.error(response);
                dispatch(loginFailed("An error has occured"));
            }
        });
    };
    const onFinishFailed = (errorInfo: any) => {
        // console.log(errorInfo);
        return true;
    };

    return (
        <Row>
            <Col span={12} offset={6}>
                <h2 className={styles.loginHeader}>Login</h2>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    onSubmitCapture={() => dispatch(login())}
                >
                <Form.Item
                    label="Username"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!'
                        },
                        {
                            type: 'email',
                            message: 'Please enter a valid email address.'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}