import { message } from "antd";
import { LoginPayload, LoginResponse } from "../types/login.types";

const url: string = process.env.REACT_APP_API_BASE_URL + "/login";

function loginService(payload: LoginPayload): Promise<LoginResponse> {
    return fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then(
        (response) => response.json()
    ).then((data) => {
        return data;
    }).catch((error: Error) => {
        // console.log(error);
        message.error(error.message);
    })
}

export default loginService;